<template>
  <base-mobile-page>
    <template v-slot:main>
        <el-main style="margin:20px auto;max-width:1300px;width:100%;">
          <el-breadcrumb separator-class="el-icon-arrow-right" style="padding:0px 5px;">
            <el-breadcrumb-item to="/account">My Account</el-breadcrumb-item>
            <el-breadcrumb-item>Order History</el-breadcrumb-item>
            <el-breadcrumb-item>{{activeStatus}}</el-breadcrumb-item>
          </el-breadcrumb>
          <!--<el-radio-group style="margin-top:20px;" v-model="activeStatus" fill="#303133">
            <el-radio-button label="Paid"></el-radio-button>
            <el-radio-button label="Delivered"></el-radio-button>
            <el-radio-button label="Reviewed"></el-radio-button>
          </el-radio-group>-->
          <order-history :status="activeStatus"></order-history>
      </el-main>
    </template>
  </base-mobile-page>
</template>

<script>
import ModelOrders from '../viewmodel/ModelOrders.vue'
import BaseMobilePage from '../../components/base/BaseMobilePage.vue'
import OrderHistory from '../../components/mobile/OrderHistory.vue'

export default {
    extends: ModelOrders,
    components: { BaseMobilePage, OrderHistory },
}
</script>

<style>

</style>