<template>
  <div v-loading="loading" id="orderHistoryItem" style="margin-top:0px;">
    <div style="font-weight:bold;">Order Number: {{item.ID}}</div>
    <div style="display:-webkit-box;color:#b7b7b7;margin: 10px 0px;">
      <div>{{orderDate}}</div>
      <div style="margin: 0 10px;">|</div>
      <div>{{item.items.length}} items</div>
    </div>
    <div style="display:-webkit-box;">
      <div v-for="product in orderFirstThreeItems" :key="product.ID">
        <el-image style="width:80px;height:80px;margin-right:10px;" :src="product.product_image"></el-image>
      </div>
    </div>
    <div style="display:-webkit-box; margin: 10px 0px;" @click="viewDetailClick">
      <div>View Order</div>
      <div style="-webkit-box-flex:1;"></div>
      <div><i class="el-icon-arrow-right"></i></div>
    </div>
    <el-divider></el-divider>
  </div>
</template>

<script>
import orderApi from '../../api/order'
import util from '../../js/util'
import { mapState } from 'vuex'

export default {
    name: 'orderHistoryItem',
    props: ['item'],
    data(){
      return {
        loading: false,
      }
    },
    computed: {
      ...mapState('client', ['token']),
      orderName(){
        if(this.item == null || this.item.items.length == 0) return 'NO Items'
        return this.item.items[0].product_name + '...' 
      },
      orderFirstThreeItems(){
        if(this.item == null) return []
        if(this.item.items.length < 3) return this.item.items
        return this.item.items.slice(0, 3)
      },
      orderDate(){
        if(this.item == null) return ''
        var date = new Date(this.item.CreatedAt)
        console.log(date)
        var week = util.getWeedDay(date.getDay())
        var month = util.getMonth(date.getMonth())
        return week + ', ' + date.getDate() + ' ' + month + ' ' + date.getFullYear()
      },
      orderStatus(){
        if(this.item == null) return ''
        return this.item.status
      }
    },
    methods: {
      viewDetailClick(){
        window.location.href = '/order/' + this.item.ID
      },
      confirmDeliverClick(){
        this.loading = true
        orderApi.confirmDeliver(this.token, this.item.ID).then(()=>{
          this.$emit('onStatusChanged', 'delivered')
          this.loading = false
        }).catch(()=>{
          this.loading = false
        })
      }
    }
}
</script>

<style>

</style>